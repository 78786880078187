import React from "react";
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import { toaster } from "../utils/toast";
import { services } from "../services";
import link2Outline from "@iconify/icons-eva/link-2-outline";
import Collapse from "@kunukn/react-collapse";
import styled from "styled-components";
import ArrowDownFill from "@iconify/icons-eva/arrow-down-fill";

// import { Icon } from '@iconify/react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
} from "@mui/material";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../components/_dashboard/user";
//
import USERLIST from "../_mocks_/user";
import {
  capitalizeFirstLetter,
  compactAddress,
  getContractInstance,
} from "src/contractData/function";
import getContractAddresses from "src/contractData/addresses";
import { web3 } from "src/web3";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import { enableMetamask, getWeb3 } from "src/helper/metamask";
import axios from "../config";
import { duration } from "moment";
import { element } from "prop-types";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srNo", label: "Sr No", alignRight: false },
  { id: "poolType", label: "Pool Type", alignRight: false },
  { id: "poolName", label: "Pool Name", alignRight: false },
  { id: "contractAddress", label: "Contract Address", alignRight: false },
  { id: "duration", label: "Duration", alignRight: false },
  { id: "staked", label: "Staked Balance", alignRight: false },
  { id: "total", label: "Total reward Balance", alignRight: false },
  { id: "reward", label: "Reward Balance", alignRight: false },

  // { id: "tokenAddress", label: "Token Address", alignRight: false },
  // { id: "loyalityPoints", label: "Loyality Points", alignRight: false },
  { id: "" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "30px",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [addFundsModal, setAddFundsModal] = useState(false);
  const [selectedPool, setSelectedPool] = useState(-1);
  const [poolData, setPoolData] = useState([]);
  const [amount, setAmount] = useState("");
  const [web3Data, setWeb3Data] = useState();
  const [allowance, setAllowance] = useState();
  const [userBalance, setUserBalance] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [claim, setClaim] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const callUserData = async () => {
    let _web3Data = await getWeb3();
    if (_web3Data.isLoggedIn) {
      setWeb3Data(_web3Data);
    } else {
      _web3Data = await enableMetamask();
      setWeb3Data(_web3Data);
    }
  };
  const checkAllowance = async () => {
    if (!web3Data.isLoggedIn)
      return toaster(`${"Please connect to metamask"}`, "error");
    const tokenContractInstance = getContractInstance(
      poolData[selectedPool].contractType == "staking"
        ? "RaiserToken"
        : "lpToken",
      poolData[selectedPool].tokenAddress
    );

    setAllowance(
      Number(
        await tokenContractInstance.methods
          .allowance(
            web3Data.accounts[0],
            poolData[selectedPool].contractAddress
          )
          .call()
      )
    );

    setUserBalance(
      web3.utils.fromWei(
        await tokenContractInstance.methods
          .balanceOf(web3Data.accounts[0])
          .call(),
        "ether"
      )
    );
    setTotalSupply(await tokenContractInstance.methods.totalSupply().call());
  };

  const getPools = async () => {
    const url = `/pools/list`;
    const sendRequest = await services.Get(url);
    if (sendRequest.status === 200) {
      getContractData(sendRequest.data.data);

      // checkIsConnected();
    } else if (sendRequest.message === "NOT_AUTHORIZED") {
      window.location.replace("/");
    }
  };

  useEffect(() => {
    getPools();
    callUserData();
  }, []);

  useEffect(() => {
    if (selectedPool >= 0) checkAllowance();
  }, [selectedPool]);
  async function getContractData(pools) {
    let _poolData = [];
    // const stakePools = [7, 14, 30, 60, 'farmingContract'];
    const allPoolDetails = await Promise.all(
      pools.map(async (pool) => {
        const obj = {};

        // const address = getContractAddresses[];
        const ContractInstance = getContractInstance(
          pool.contractType,
          pool.contractAddress
        );
        obj.poolName = pool.poolName;
        obj.tokenAddress = pool.tokenAddress;
        obj.loyalityPoints = pool.loyalityPoints;
        obj.contractAddress = pool.contractAddress;
        obj.stakedBalance = +web3.utils.fromWei(
          await ContractInstance.methods.stakedBalance().call()
        );
        obj.totalReward = +web3.utils.fromWei(
          await ContractInstance.methods.totalReward().call()
        );
        obj.rewardBalance = +web3.utils.fromWei(
          await ContractInstance.methods.rewardBalance().call()
        );

        obj.contractId = pool.id;
        obj.name = capitalizeFirstLetter(pool.contractType);
        obj.poolName = capitalizeFirstLetter(pool.poolName);
        obj.tokenName = pool.tokenName;
        obj.duration =
          obj.name == "Farming"
            ? "-"
            : +(await ContractInstance.methods.lockDuration().call()) / 24;
        _poolData.push(obj);
      })
    );
    setPoolData(_poolData);
    setClaim(_poolData);
    setFilteredData([..._poolData]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  const addFunds = async () => {
    const ContractInstance = getContractInstance(
      poolData[selectedPool].name,
      poolData[selectedPool].contractAddress
    );
    if (!web3Data.isLoggedIn)
      return toaster(`${"Please connect to metamask"}`, "error");
    await ContractInstance.methods
      .addReward(web3.utils.toWei(amount))
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        return toaster(`${"Transaction submitted"}`, "warn");
      })
      .on("receipt", (receipt) => {
        return toaster(`${"Transaction Successful"}`, "success");
      })
      .on("error", (error) => {
        return toaster(`${"Transaction Reverted"}`, "error");
      });
  };
  const approve = async () => {
    const tokenContractInstance = getContractInstance(
      poolData[selectedPool].contractType == "staking"
        ? "RaiserToken"
        : "lpToken",
      poolData[selectedPool].tokenAddress
    );
    if (!web3Data.isLoggedIn) {
      return toaster(`${"Please connect to metamask"}`, "error");
    }
    await tokenContractInstance.methods
      .approve(poolData[selectedPool].contractAddress, totalSupply)
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        return toaster(`${"Transaction submitted"}`, "warn");
      })
      .on("receipt", (receipt) => {
        return toaster(`${"Transaction Successful"}`, "success");
      })
      .on("error", (error) => {
        return toaster(`${"Transaction Reverted"}`, "error");
      });
  };

  const deletePool = async (id) => {
    const url = `/pools/delete/${id}`;
    const headers = {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("token"),
    };

    const sendRequest = await axios.delete(url, { headers: headers });

    if (sendRequest.status === 200) {
      getPools();

      return toaster(`${"Pool is deleted successfully"}`, "success");
    } else {
      window.location.replace("/");
    }
  };

 
  
  return (
    <Page title="Pools | Raiser Snapshot">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Pools
          </Typography>
          <ButtonList>
            <Button
              variant="contained"
              // component={RouterLink}
              onClick={() => {
                callUserData();
                // this.connectMetamask();
              }}
              startIcon={
                web3Data?.accounts[0] ? null : <Icon icon={link2Outline} />
              }
              sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
            >
              {web3Data?.accounts[0]
                ? compactAddress(web3Data.accounts[0])
                : "Connect Metamask"}
            </Button>{" "}
         
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/addpools"
              startIcon={<Icon icon={plusFill} />}
              sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
            >
              Add Pools
            </Button>
          </ButtonList>
        </Stack>

        <Card style={{backgroundColor: '#151517'}}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                  pooltab={true}
                />
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((pool, id) => {
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell spacing={2}>{id + 1}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {pool.name}
                          </TableCell>
                          <TableCell spacing={2}>{pool.poolName}</TableCell>
                          <TableCell align="left">
                            {compactAddress(pool.contractAddress)}
                          </TableCell>
                          <TableCell align="left">{pool.duration}</TableCell>
                          <TableCell align="left">
                            {pool.stakedBalance}
                          </TableCell>
                          <TableCell align="left">{pool.totalReward}</TableCell>
                          <TableCell align="left">
                            {pool.rewardBalance}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu
                              menu="pools"
                              setSelectedPool={setSelectedPool}
                              setAddFundsModal={setAddFundsModal}
                              id={id}
                              contractId={pool.contractId}
                              deletePool={deletePool}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[20, 30, 40]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal
        open={addFundsModal}
        onClose={() => setAddFundsModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{backgroundColor: '#151517'}}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <h3 style={{ textAlign: "center", marginBottom: "30px" }}>
              {+allowance ? "Add Reward" : "Approve"}
            </h3>
          </Typography>
          {+allowance ? (
            <TextField
              fullWidth
              autoComplete="contract"
              type="text"
              label="Amount"
              onChange={(e) => setAmount(e.target.value)}
              // {...getFieldProps("contract")}
            />
          ) : null}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // loading={isSubmitting}
            style={{ marginTop: "25px" }}
            onClick={() => (+allowance ? addFunds() : approve())}
            sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
          >
            {+allowance ? "Add" : "Approve"}
          </LoadingButton>
        </Box>
      </Modal>
    </Page>
  );
}

// styled components
const ButtonList = styled.div`
  display: flex;
  align-items: center;
  Button {
    margin: 0px 5px;
    &.active {
      background-color: #048cfc;
    }
  }
`;

const CompletedBox = styled.div`
  position: relative;
  .collapse-css-transition {
    position: absolute;
    top: 22px;
    right: 0px;
    left: auto;
    z-index: 9;
    width: 200px;
    transition: height 252ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
`;
