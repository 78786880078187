import * as React from "react";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Card, Stack, Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Page from "../components/Page";
import { services } from "../services/index";
import { toaster } from "../utils/toast";


export default function GenerateTierListReg() {
  const [isLoading, setLoading] = useState(false);
  const [network, setNetwork] = React.useState("");
  const [tier, setTier] = useState('');
  const [isSubmitting, setSubmit] = React.useState(false);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [igoName, setIgoName] = useState([]);
  const [selectedIgoName, setSelectedIgoName] = useState("");
  const [result, setResult] = useState(null);
 
  useEffect(() => {
    async function getCountryList() {
      const url = `/user/getUniqueCountries`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setCountry(sendRequest.data.data);
      } else {
        // setLoading(false);
      }
    }
    getCountryList();
  }, []);


  useEffect(() => {
    async function getIgoList() {
      const url = `/apply-proj/list?approved`;
      const sendRequest = await services.Get(url);

      if (sendRequest.status === 200) {
        setIgoName(sendRequest.data.data);
      } else {
      }
    }
    getIgoList();
  }, []);

  const handleChange = (event) => {
    setNetwork(event.target.value);
  };
  const generateTierList = async () => {
    setSubmit(true);

    try {
        
            const url=`/userParticipate/participants/${selectedIgoName}/${network}`;
            const sendRequest = await services.Get(url);

            if (sendRequest.data.success) {
                setSubmit(false);
                toaster(`Your request has been received`, "success");
            }
            setResult(sendRequest.data);
        } catch (error) {
          setResult({ success: false, message: 'Error generating CSV.' });
        }
  };
  return (
    <Page title="Generate Tier List | Raiser Snapshot">
      <Container>
        <Card style={{ padding: "20px", backgroundColor: '#151517' }}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Snapshot Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={network}
                label="Select Contract Type"
                onChange={handleChange}
              >
              
                <MenuItem value={`0`} style={{backgroundColor: '#151517'}}>Tier 0 (0 - 249.00)</MenuItem>
                <MenuItem value={`1`} style={{backgroundColor: '#151517'}}>Tier 1 (250 - 999.99)</MenuItem>
                <MenuItem value={`2`} style={{backgroundColor: '#151517'}}>Tier 2 (999.99 - 2499.99)</MenuItem>
                <MenuItem value={`3`} style={{backgroundColor: '#151517'}}>Tier 3 (2500 - 4999.99)</MenuItem>
                <MenuItem value={`4`} style={{backgroundColor: '#151517'}}>Tier 4 (5000 - 7499.99)</MenuItem>
                <MenuItem value={`5`} style={{backgroundColor: '#151517'}}>Tier 5 (7500 - 9999.99)</MenuItem>
                <MenuItem value={`6`} style={{backgroundColor: '#151517'}}>Tier 6 (10,000 - 24,999.99)</MenuItem>
                <MenuItem value={`7`} style={{backgroundColor: '#151517'}}>Tier 7 (25,000 - 49,999.99)</MenuItem>
                <MenuItem value={`8`} style={{backgroundColor: '#151517'}}>Tier 8 (50,000 - 99,999.99)</MenuItem>
                <MenuItem value={`9`} style={{backgroundColor: '#151517'}}>Tier 9 (100000+)</MenuItem>
              </Select>
            </FormControl>

          

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select IDO name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Country"
                onChange={(e) => {
                  setSelectedIgoName(e.target.value);
                }}
                value={selectedIgoName}
                required
              >
                <MenuItem value={""} style={{backgroundColor: '#151517'}}></MenuItem>
                {igoName.map((igoName) => (
                  <MenuItem value={`${igoName?.projectName}`} style={{backgroundColor: '#151517'}}>
                  {igoName?.projectName}
                </MenuItem>
                ))}
              </Select>
            </FormControl>




        
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={() => {
              generateTierList();
            }}
            style={{ marginTop: "25px", color: "#000000" }}
            sx={{ background: "linear-gradient(267.83deg, #FBAE48 6.21%, #F05C87 107.97%)" }}
          >
            Generate Tier List
          </LoadingButton>
        </Card>
      </Container>
    </Page>
  );
}
